











import Vue from "vue"

import ReservierungContent from "@/components/ReservierungContent.vue"

export default Vue.extend({
  components: {
    ReservierungContent
  }
})
