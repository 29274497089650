

















































































































































import { addDays } from "date-fns"
import { range } from "lodash-es"
import Vue from "vue"

import BaseErrorText from "@/components/BaseErrorText.vue"
import {
  formatDate,
  patchHTMLInputTime
} from "@/util/date"
import ReservierungRequestDone from "@/components/ReservierungRequestDone.vue"
import { titles as titleOptions } from "@/util/forms/selectOptions"

/*
function newDevValues() {
  return {
    date: formatDate(addDays(new Date(), 1)),
    time: "19:00",
    number_persons: 2,
    title: "male",
    name_first: "foo",
    name_last: "bar",
    telephone: "030 123 3456",
    email: "saul_jimenez@zoho.com",
    note: "testestest",
    consent_privacy: true
  }
}
*/

export default Vue.extend({
  components: {
    BaseErrorText,
    ReservierungRequestDone
  },

  data() {
    return {
      values: {},
      minDate: new Date(),
      maxDate: addDays(new Date(), 30),
      // vue-formulate requires array of options to be string[]
      numberPersonOptions: range(1, 16).map(x => x.toString(10)),
      titleOptions,
      mutationDone: false,
      mutationError: null as Error | null
    }
  },

  methods: {
    async onSubmit(data: Record<string, string | boolean | number>): Promise<void> {
      // Patch fields.
      data.time = patchHTMLInputTime(data.time as string)
      // <select> values are coerced to string
      data.number_persons = parseInt(data.number_persons as string, 10)

      // Delete here. Adding `ignored` on `FormulateInput` disables validation.
      delete data.consent_privacy

      // Add required fields not editable by user.
      data.reservation_status = "pending"

      // Ensure textbox fields are present even if unselected.
      data.newsletter_consent = data.newsletter_consent === true

      try {
        const res = await this.$apollo.mutate({
          mutation: require("@/graphql/mutations/createReservation.graphql"),
          variables: { data }
        })
        if (!res.data?.create_reservations_item?.id) {
          throw new Error("failed to create reservation")
        }
        this.mutationDone = true
        this.mutationError = null
        this.$nextTick(() => {
          // $refs.mutationDone is rendered after $nextTick
          (this.$refs.mutationDone as Vue).$el.scrollIntoView(true)
        })
      } catch (e) {
        this.mutationError = e
      }
    },

    // The following methods are added for availabilty in <template>.
    addDays,
    formatDate
  }
})
