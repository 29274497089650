










































import Vue from "vue"

import BaseLink from "@/components/BaseLink.vue"
import { email } from "@/model/constants"
import {
  Link,
  newTelephoneLink
} from "@/model/Link"
import {
  newDateDE,
  isDuringWeekend
} from "@/util/date"
import ReservierungRequest from "@/components/ReservierungRequest.vue"

export default Vue.extend({
  components: {
    BaseLink,
    ReservierungRequest
  },

  data() {
    return {
      isWeekend: isDuringWeekend(newDateDE(), 15)
    }
  },

  computed: {
    links(): Link[] {
      return [
        newTelephoneLink("0711 294 744"),
        this.newLinkEmail()
      ]
    }
  },

  methods: {
    newLinkEmail(): Link {
      // generate querystring with https://mailto.vercel.app/
      const qry = "subject=Reservierung%3A%20Anfrage&body=Ich%20w%C3%BCrde%20gerne%20einen%20Tisch%20reservieren%20f%C3%BCr%20(Name%2C%20Datum%2C%20Uhrzeit%2C%20Anzahl%20Personen%2C%20Telefon)%3A"
      return {
        text: email,
        to: `mailto:${email}?${qry}`
      }
    }
  }
})
