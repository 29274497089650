











import Vue from "vue"

import NucleoGFCheck from "@/components/svg/NucleoGFCheck.vue"

export default Vue.extend({
  components: {
    NucleoGFCheck
  }
})
