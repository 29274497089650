import { lightFormat } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

/** Returns a new `Date` (current instance) in timezone `Europe/Berlin`. */
export function newDateDE(): Date {
  return utcToZonedTime(new Date(), "Europe/Berlin")
}

export function isDuringWeekend(d: Date, fridayWEStartHours: number): boolean {
  const day = d.getDay()
  if (day === 5 && d.getHours() >= fridayWEStartHours) {
    return true // Friday after WE trigger
  }
  if (day === 6 || day === 0) {
    return true // Saturday || Sunday
  }
  return false
}

/** Formats `d` according to `2021-08-23`. */
export function formatDate(d: Date): string {
  return lightFormat(d, "yyyy-MM-dd")
}

/**
 * Value `s` is either `hh:mm` or `hh:mm:ss` [see](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time#time_value_format).
 *
 * Directus in combination with sqlite has buggy UI if time misses seconds.
 * Hence, this function adds `:00` if `s` is `hh:mm` without seconds.
 *
 * @param s - value of <input type="time">
 * @returns time with seconds
 */
export function patchHTMLInputTime(s: string): string {
  if (/^\d\d:\d\d$/.test(s)) {
    s += ":00"
  }
  return s
}
