// To avoid auto-selecting first option either
// - provide a placeholder or
// - use OptionArray with first entry having `value: ""`
type Options = Record<string, string>

// Uncomment if required.
// type OptionArray = Array<{ value: string, label: string }>

export const titles: Options = {
  female: "Frau",
  male: "Herr",
  diverse: "Divers"
}
